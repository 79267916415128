import type { Domain } from 'effector';

import { domain as globalDomain } from '@core/domain';

import type { HistoryLogKind, PaymentType } from './model';

export const domain: Domain = globalDomain.domain('@paymentService');

const PAID_MANUALLY_PREFIX = 'Paid manually';

export const getDescription = (kind: HistoryLogKind, type?: PaymentType): string => {
	if (kind === 'manually_paid' && type) {
		const manuallyPaidCases: Partial<Record<PaymentType, string>> = {
			dealer_to_mw: `${PAID_MANUALLY_PREFIX} - From wallet`,
			fee: `${PAID_MANUALLY_PREFIX} - Fees`,
			finance_account_to_finance_house: `${PAID_MANUALLY_PREFIX} - Finance`,
			mw_to_seller: `${PAID_MANUALLY_PREFIX} - To seller`,
			seller_to_mw: `${PAID_MANUALLY_PREFIX} - From seller`,
		};
		return manuallyPaidCases[type] || PAID_MANUALLY_PREFIX;
	}
	const cases: Partial<Record<HistoryLogKind, string>> = {
		counter_offer_created: 'Price reduction submitted',
		initial_offer_created: 'Sold - Documents reviewed',
		manually_paid: PAID_MANUALLY_PREFIX,
		offer_approved: 'Price reduction accepted',
		payment_to_fee_account_succeeded: 'Payment for fees complete',
		payment_to_finance_company_succeeded: 'Finance paid manually',
		payment_to_motorway_succeeded: 'Payment taken from dealer’s wallet',
		payment_to_seller_succeeded: 'Payment sent to seller',
		sms_for_offer_approval_sent: 'SMS sent for approval',
	};

	return cases[kind] || kind;
};
